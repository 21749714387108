/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import CustomLayout from "./src/components/wrapPageElement"
import "./src/styles/global.css"

export const wrapPageElement = CustomLayout
