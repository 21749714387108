import React from "react"
import { Link } from "gatsby"
import { Link as ScrollLink } from "react-scroll"
import PropTypes from "prop-types"
import { linkPropTypes } from "@/utils/types"

const CustomLink = ({ link, children, onClick }) => {
  const isInternalLink = link.url.startsWith("/")
  const isFileLink = link.url.includes(".")

  // For internal links, use the Next.js Link component
  if (isInternalLink && !isFileLink) {
    return <Link to={link.url}>{children}</Link>
  }

  const isScrollLink = link.url.startsWith("#")
  if (isScrollLink) {
    return (
      <ScrollLink
        to={link.url.substring(1)}
        spy={true}
        smooth={true}
        duration={500}
        className="cursor-pointer"
        onClick={onClick}
      >
        {children}
      </ScrollLink>
    )
  }

  // Plain <a> tags for external links
  return (
    <a
      href={link.url}
      // Change target and rel attributes is newTab is turned on
      target={link.newTab ? "_blank" : "_self"}
      rel="noreferrer"
    >
      {children}
    </a>
  )
}

CustomLink.propTypes = {
  link: linkPropTypes,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default CustomLink
